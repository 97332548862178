<template>
  <div class="cont">
     <!-- 面包屑 -->
     <el-row class="crumbs-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
           <el-breadcrumb-item>业务中台</el-breadcrumb-item>
           <el-breadcrumb-item>优惠券</el-breadcrumb-item>
           <el-breadcrumb-item>领取列表</el-breadcrumb-item>
        </el-breadcrumb>
     </el-row>
      <el-row class="content-box">
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>券编号：</label>
                  <el-input class="width-220"  v-model="param.couponCode" placeholder="填写优惠券编号" clearable></el-input>
               </el-row>
               <el-row class="search-item">
                  <label>使用类型：</label>
                  <el-select v-model="param.state"  class="width-220" :placeholder="$t('msg.select')">
                     <el-option
                        v-for="(label, value) in dictData['coupon-state']"
                        :key="value"
                        :label="label"
                        :value="value">
                     </el-option>
                  </el-select>
               </el-row>
               <el-row class="search-item">
                  <label>券类型：</label>
                  <el-select v-model="param.type"  class="width-220" :placeholder="$t('msg.select')">
                     <el-option
                        v-for="(label, value) in dictData['coupon-type']"
                        :key="value"
                        :label="label"
                        :value="value">
                     </el-option>
                  </el-select>
               </el-row>
            </el-row>
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>搜索范围：</label>
                  <el-select v-model="param.search"  class="width-220" :placeholder="$t('msg.select')">
                     <el-option
                           v-for="item in searchList"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                     </el-option>
                  </el-select>
               </el-row>
               <el-button class="bg-gradient" type="primary" icon="el-icon-search"  @click="handleQuery(true)"><span v-text="$t('msg.search')">搜索</span></el-button>
               <el-button type="primary" icon="el-icon-refresh-right"  plain @click="handleQuery(false)"><span v-text="$t('msg.reset')">重置</span></el-button>
            </el-row>
         </el-row>
         <!-- 主体内容 -->
         <div class="table-box">
            <!-- 表格 -->
            <el-table
                  ref="mainTable"
                  :data="tableData"
                  v-loading="loading"
                  style="width: 100%"
                  :stripe="true"
                  border fit>
               <el-table-column label="序号" width="60">
                  <template slot-scope="scope">
                     {{scope.$index + 1}}
                  </template>
               </el-table-column>
               <el-table-column prop="couponCode" label="优惠券编号" min-width="50"></el-table-column>
               <el-table-column prop="mainTitle" label="优惠券名称" min-width="60"></el-table-column>
               <el-table-column label="优惠券类型" min-width="50">
                  <template slot-scope="scope">
                     {{ scope.row.type | filterType(that) }}
                  </template>
               </el-table-column>
               <el-table-column prop="name" label="姓名" min-width="40"></el-table-column>
               <el-table-column prop="mobile" label="电话" min-width="50"></el-table-column>
               <el-table-column prop="IdCard" label="证件号码" min-width="40"></el-table-column>
               <el-table-column label="状态" min-width="40">
                  <template slot-scope="scope">
                     {{ scope.row.state | filterState(that) }}
                  </template>
               </el-table-column>
               <el-table-column prop="receiveDate" label="领取时间" min-width="40"></el-table-column>
               <el-table-column prop="useDate" label="核销时间" min-width="40"></el-table-column>
               <el-table-column :label="$t('msg.operating')" fixed="right" min-width="100">
                  <template slot-scope="scope">
                  <el-button  type="text" @click="handleDetail(scope.row.id)" >
                     <el-link type="primary" v-text="$t('msg.detail')">详情</el-link>
                  </el-button>
                  <el-button type="text"  @click="handleWriteOff(scope.row.couponCode)">
                     <el-link type="primary">核销</el-link>
                  </el-button>
                  <el-button type="text" style="color: #F56C6C;" @click="handleDel(scope.row.id)">
                     <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                  </el-button>
                  </template>
               </el-table-column>
            </el-table>
         </div>
         <!-- 领取详情 -->
         <el-form label-width="105px" class="popup-box">
            <el-dialog title="优惠券领取详情" :visible.sync="detailVisible" width="660px">
               <el-row class="tit">
                  <h6>{{ details.mainTitle }}</h6>
               </el-row>
               <el-row class="text-list">
                  <el-row>
                     <el-col :span="12">
                        <p><span class="width-100">编号</span>{{ details.couponCode }}</p>
                     </el-col>
                  </el-row>
                  <el-row>
                     <el-col :span="12">
                        <p><span class="width-100">领券人姓名</span></p>
                     </el-col>
                     <el-col :span="12">
                        <p><span class="width-100">领券人电话</span></p>
                     </el-col>
                  </el-row>
                  <el-row>
                     <el-col :span="12">
                        <p><span class="width-100">证件号码</span></p>
                     </el-col>
                     <el-col :span="12">
                        <p><span class="width-100">微信号</span></p>
                     </el-col>
                  </el-row>
                  <el-row>
                     <el-col :span="12">
                        <p><span class="width-100">领券渠道</span>{{ details.receiveChannel | filterChannel(that) }}</p>
                     </el-col>
                     <el-col :span="12">
                        <p><span class="width-100">领券时间</span>{{ details.receiveDate }}</p>
                     </el-col>
                  </el-row>
                  <el-row>
                     <el-col :span="12">
                        <p><span class="width-100">状态</span>{{ details.state | filterState(that) }}</p>
                     </el-col>
                     <el-col :span="12">
                        <p><span class="width-100">核销时间</span></p>
                     </el-col>
                  </el-row>
                  <el-row>
                     <el-col :span="12">
                        <p><span class="width-100">优惠券类型</span></p>
                     </el-col>
                     <el-col :span="12">
                        <p><span class="width-100">参与商品数</span></p>
                     </el-col>
                  </el-row>
                  <el-row>
                     <el-col :span="12">
                        <p><span class="width-100">优惠内容</span></p>
                     </el-col>
                     <el-col :span="12">
                        <p><span class="width-100">优展示模板</span></p>
                     </el-col>
                  </el-row>
                  <el-row>
                     <el-col :span="12">
                        <p><span class="width-100">发行量</span></p>
                     </el-col>
                     <el-col :span="12">
                        <p><span class="width-100">优惠券简称</span></p>
                     </el-col>
                  </el-row>
                  <el-row>
                     <el-col :span="12">
                        <p><span class="width-100">活动时间</span></p>
                     </el-col>
                     <el-col :span="12">
                        <p><span class="width-100">单用户限领数</span></p>
                     </el-col>
                  </el-row>
                  <el-row>
                     <el-col :span="24">
                        <p><span class="width-100">优惠券简介</span></p>
                     </el-col>
                  </el-row>
               </el-row>
               <el-row class="btm-btn">
                  <el-button class="bg-gradient"  @click="detailVisible = false">关闭</el-button>
               </el-row>
            </el-dialog>
         </el-form>
         <!-- 分页 -->
         <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
      </el-row>
   </div>
</template>

<script>
import { urlObj , business } from "@/api/interface"
import { mapState } from 'vuex'
import { getDict } from "@/common/js/common"
import {coupon} from "../../../../api/interface/business";
export default {
   data(){
      return{
         loading: true,
         tableData: [],
         limit: sessionStorage.getItem('pageSize') * 1,
         total: 1,
         page: 1,
         detailVisible: false,
         that: this,
         searchList: [
            { label: '全部', value: '' },
            { label: '姓名', value: 0 },
            { label: '电话', value: 1 },
            { label: '证件号', value: 2 }
         ],
         param: {
            state: '',
            type: '',
            search: '',
            couponCode: ''
         },
         details: {
            mainTitle: '',
            couponCode: '',
            receiveDate: '',
            receiveChannel: 0,
            state: '',
         },
         // 提示文本
         del_success: "删除成功！",
         add_success: "添加成功！",
         update_success: '修改成功！',
         userName_no_null: '用户名不能为空！',
         mobile_no_null: '手机号不能为空！',
         nickName_no_null: '昵称不能为空！',
         confirm: '确定',
         cancel: '取消',
         confirm_remove: '确定移除？',
         prompt: '提示！',
         code:'',
         stocktype:'', //劵类型
         undercarriage:false
      }
   },
   computed: {
      ...mapState(['dictData', 'hotelInfo'])
   },
   created() {
      getDict(['coupon-type', 'coupon-state'])
   },
   methods: {
      // 优惠券领取列表
      getReceives(){
         const url = coupon.couponReceive + `?limit=${this.limit}&page=${this.page}`
         const param = { ...this.param, hotelId: this.hotelInfo.id }
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success) {
               this.total = res.total
               this.loading = false
               this.tableData = res.records
            }
         })
      },
      // 改变每页数
      pageChange(num){
         this.limit = num
         this.getReceives()
      },
      // 改变当前页
      handlePaging(num){
         this.page = num
         this.getReceives()
      },
      // 查询领取列表
      handleQuery(bool){
         this.page = 1
         if (bool) return this.getReceives()
         for (let k in this.param) this.param[k] = ''
      },
      // 删除优惠券
      handleDel(id){
         this.$confirm('您确定要删除当前优惠券活动吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
         }).then(() => {
            let url = coupon.delCouponReceive + `/${id}`
            this.$axios.get(url, {}).then(() => {
               this.$message({
                  showClose: true,
                  message: '删除成功！',
                  type: 'success'
               })
               this.getReceives()
            })
         })
      },
      // 核销优惠券
      handleWriteOff(couponCode){
         this.$confirm('确定进行核销吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
         }).then(() => {
            let url = coupon.couponWriteOff + `/${couponCode}`
            this.$axios.get(url, {}).then(() => {
               this.$message({
                  showClose: true,
                  message: '核销成功！',
                  type: 'success'
               })
               this.getReceives()
            })
         })
      },
      // 优惠券领取详情
      handleDetail(id){
         const url = coupon.couponReceiveDetail + `/${id}`
         this.$axios.get(url, {}).then(res => {
            if (res.success) {
               this.detailVisible = true
               this.details = res.records
            }
         })
      },
   },
   filters: {
      filterType(val, that){
         if (val && that.dictData['coupon-type']) return that.dictData['coupon-type'][val] || val
      },
      filterState(val, that){
         if (val && that.dictData['coupon-state']) return that.dictData['coupon-state'][val] || val
      },
      filterChannel(val, that){
         if (val && that.dictData['receive-channel']) return that.dictData['receive-channel'][val] || val
      }
   },
   watch: {
      hotelInfo: {
         handler(newVal, oldVal) {
            (oldVal === undefined || newVal.id !== oldVal.id) && this.getReceives()
         },
         immediate: true
      }
   }
}
</script>

<style lang="scss" scoped>
.popup-box{
   .tit{
      text-align: center;
      h6{ font-size: 18px; margin-bottom: 10px }
      p{ font-size: 16px }
   }
   .right-price{ text-align: right; span{ font-size: 26px; color: red } }
   .table-box{ width: 70%; margin: 0 auto }
   .text-list{
      margin: 20px auto 0; line-height: 30px; color: #666666;
      span{ display: inline-block; text-align: right; margin-right: 15px; color: #222222 }
   }
   .btm-btn{ margin: 20px 0 0 0; text-align: center }
}
</style>
